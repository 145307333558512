import { forwardRef } from 'react'

import { fonts, Button } from '@lumoslabs/lumosity-storybook'
import { SnackbarContent, SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack'
import styled from 'styled-components'

import Card from '~/components/ui/Card'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

const { Body1Bold } = fonts

interface LogoutConfirmationProps {
  id: SnackbarKey
  message: SnackbarMessage
  handleConfirm: () => Promise<void>
}

const LogoutConfirmation = forwardRef<HTMLDivElement, LogoutConfirmationProps>((props, ref) => {
  const t = useTranslationForNamespace('common')
  const { message, id, handleConfirm, ...other } = props
  const { closeSnackbar } = useSnackbar()
  return (
    <SnackbarContent ref={ref} id={id as string} {...other} style={{ display: 'grid' }}>
      <BackgroundImageContainer>
        <BackgroundImage>
          {/* reference image already loaded invisibly into DOM via <SnackbarProvider> */}
          <use href='#leafy-background' width='100%' height='110%' />
        </BackgroundImage>
      </BackgroundImageContainer>

      <LogoutConfirmationCard>
        <Body1Bold>{message}</Body1Bold>
        <LogoutActionContainer>
          <Button
            onClick={() => {
              handleConfirm()
              closeSnackbar()
            }}
          >
            {t('buttons.logout')}
          </Button>
          <Button
            onClick={() => {
              closeSnackbar()
            }}
          >
            {t('buttons.stayLoggedIn')}
          </Button>
        </LogoutActionContainer>
      </LogoutConfirmationCard>
    </SnackbarContent>
  )
})

const BackgroundImageContainer = styled.div`
  grid-area: 1 / -1 / 1 / -1;
  width: 400px;
`

const BackgroundImage = styled.svg.attrs({
  viewBox: '0 1 152 50',
  preserveAspectRatio: 'xMaxYMax slice',
})`
  border-radius: 16px;
  height: 100%;
  width: 100%;
`

const LogoutConfirmationCard = styled(Card)`
  grid-area: 1 / -1 / 1 / -1;
  color: ${({ theme }) => theme.colors.inkBase};
  background: none;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-height: min-content;
`

const LogoutActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 15px 0 8px;
`

export default LogoutConfirmation
