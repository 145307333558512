import _ from 'lodash'

import { GetTodaysWorkoutQuery, WorkoutGameFragment } from '~/graphql/generated/schema'

export const mapWorkout = (workout: { id: any; slots: any; createdAt?: any }, _hasPremium = true): Workout => {
  // Most uses of this function need the entire workout so Premium is the default, for getNextUnplayedGame we'll use the correct sub status
  const slots = {
    slot1: workout.slots.slot1,
    slot2: workout.slots.slot2,
    slot3: workout.slots.slot3,
    slot4: workout.slots.slot4,
    slot5: workout.slots.slot5,
    slot6: workout.slots.slot6,
    slot7: workout.slots.slot7,
  }

  const mappedWorkout: Workout = {
    id: workout.id,
    createdAt: workout.createdAt,
    slots: slots,
  }

  return mappedWorkout
}

export const getWorkoutSlotNumber = (queryResult: GetTodaysWorkoutQuery, slug: string): number | undefined => {
  const slots = queryResult?.todaysWorkout.slots
  if (!slots) {
    return undefined
  }

  const currentSlot = _.findKey(slots, (workoutGame: WorkoutGameFragment | undefined | string) => {
    if (typeof workoutGame === 'string') {
      return false
    }
    // return slots?.[slotKey as keyof WorkoutSlots].game?.slug === slug
    return workoutGame?.game?.slug === slug
  })

  if (currentSlot === undefined) {
    return undefined
  }

  const allSlots = Object.keys(slots)
  const gameSlot = allSlots.indexOf(currentSlot)
  // slot is returned with indexOf because typename is index 0: ["__typename","slot1","slot2","slot3","slot4","slot5","slot6","slot7"]

  if (gameSlot === undefined) {
    return undefined
  }
  return gameSlot
}

export const getWorkoutSlotForSlug = (queryResult: GetTodaysWorkoutQuery, slug: string) => {
  const { slots } = mapWorkout(queryResult.todaysWorkout)

  const currentSlot = Object.values(slots).find((slot) => slot.game.slug === slug)

  return currentSlot
}

export const getNextUnplayedGame = (queryResult: GetTodaysWorkoutQuery, hasPremium: boolean) => {
  const { todaysWorkout } = queryResult
  const { slots } = mapWorkout(todaysWorkout, hasPremium)

  return Object.values(slots).find((slot) => !slot.lastGamePlay)
}

export const getTotalGamesPlayed = (queryResult: GetTodaysWorkoutQuery) => {
  const { slots } = mapWorkout(queryResult.todaysWorkout)
  // Filter out games that do not have a gameplay and only count those that do
  const totalPlayedGames = Object.values(slots).filter((slot) => {
    return slot?.lastGamePlay
  }).length

  return totalPlayedGames
}

export const getTotalGamesCount = (_hasPremium: boolean) => {
  return 7
}
