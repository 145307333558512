import { useEffect } from 'react'

import { useRouter } from 'next/router'

import { AnalyticsEvents } from '~/context/AnalyticsContext'
import useAnalytics from '~/hooks/analytics/useAnalytics'
import useCurrentScreen from '~/hooks/analytics/useCurrentScreen'

export default function useTrackScreenView(name: string, category: string) {
  const [screenName, setScreenName] = useCurrentScreen()

  const track = useAnalytics()
  const router = useRouter()

  useEffect(() => {
    track(AnalyticsEvents.ScreenView, {
      path: router.asPath, //path portion of the URL of the page (equivalent to canonical path which defaults to location.pathname from the DOM API)
      referrer: document.referrer, //full URL of the previous page (equivalent to document.referrer from the DOM API)
      search: location.search, //query string portion of the URL of the page (equivalent to location.search from the DOM API)
      title: document.title, //title of the page (equivalent to document.title from the DOM API)
      url: location.href, //full URL of the page
      previous_screen: screenName,
      name, //the name of the page
      category, //the category of the page
    })
    setScreenName(name)
  }, [category, name, router.asPath, screenName, setScreenName, track])
}
