import { createContext } from 'react'

import { PlayContext } from '~/constants'

export const GameContext = createContext<{ slug: string; playContext: PlayContext; onSlugChange: OnSlugChange }>({
  slug: '',
  playContext: PlayContext.freePlay,
  onSlugChange: () => {
    return
  },
})
